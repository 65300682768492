import React, { useState } from 'react';
import { registerMember } from '../api/api';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

const MemberForm = () => {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [addressStreet, setAddressStreet] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [addressPostcode, setAddressPostcode] = useState('');
  const [addressCountry, setAddressCountry] = useState('');
  const [consentForPhotographs, setConsentForPhotographs] = useState(false);
  const [firstAidQualification, setFirstAidQualification] = useState(false);
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const memberData = {
      name,
      dob,
      email,
      telephone,
      address_street: addressStreet,
      address_city: addressCity,
      address_postcode: addressPostcode,
      address_country: addressCountry,
      consent_for_photographs: consentForPhotographs,
      first_aid_qualification: firstAidQualification,
      password,
    };
    await registerMember(memberData);
  };

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Date of Birth"
          type="date"
          fullWidth
          margin="normal"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Telephone"
          fullWidth
          margin="normal"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
        />
        <TextField
          label="Street Address"
          fullWidth
          margin="normal"
          value={addressStreet}
          onChange={(e) => setAddressStreet(e.target.value)}
        />
        <TextField
          label="City"
          fullWidth
          margin="normal"
          value={addressCity}
          onChange={(e) => setAddressCity(e.target.value)}
        />
        <TextField
          label="Postcode"
          fullWidth
          margin="normal"
          value={addressPostcode}
          onChange={(e) => setAddressPostcode(e.target.value)}
        />
        <TextField
          label="Country"
          fullWidth
          margin="normal"
          value={addressCountry}
          onChange={(e) => setAddressCountry(e.target.value)}
        />
        <div>
          <label>
            <input
              type="checkbox"
              checked={consentForPhotographs}
              onChange={(e) => setConsentForPhotographs(e.target.checked)}
            />
            Consent for Photographs
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={firstAidQualification}
              onChange={(e) => setFirstAidQualification(e.target.checked)}
            />
            First Aid Qualification
          </label>
        </div>
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Register Member
        </Button>
      </form>
    </Container>
  );
};

export default MemberForm;
