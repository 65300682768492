import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../logo.png';
import '../styles.css';

const Layout = ({ children }) => {
  const { user, handleLogout } = useContext(AuthContext);
  const location = useLocation();

  const isAdmin = user && user.role === 'admin';

  return (
    <>
      <AppBar position="static" className="navbar" style={{ backgroundColor: 'white' }}>
        <Toolbar>
          <img src={logo} alt="Yate Hockey Logo" className="header-logo" />
          <Typography variant="h6" style={{ flexGrow: 1, color: '#003366' }}>
            Management Portal
          </Typography>
          {user ? (
            <>
              <Button color="inherit" component={Link} to="/" style={{ color: '#003366', margin: '0 10px' }}>
                Home
              </Button>
              {isAdmin && (
                <>
                  <Button color="inherit" component={Link} to="/members" style={{ color: '#003366', margin: '0 10px' }}>
                    Members
                  </Button>
                  <Button color="inherit" component={Link} to="/matches" style={{ color: '#003366', margin: '0 10px' }}>
                    Matches
                  </Button>
                </>
              )}
              <Button color="inherit" onClick={handleLogout} style={{ color: '#003366', margin: '0 10px' }}>
                Logout
              </Button>
            </>
          ) : (
            <Button color="inherit" component={Link} to="/login" style={{ color: '#003366', margin: '0 10px' }}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <main>{children}</main>
      <footer className="footer">
        &copy; 2024 Yate Hockey Club
      </footer>
    </>
  );
};

export default Layout;
