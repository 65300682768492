import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom'; // This will help us get the childId from the URL
import { updateUserDetails, fetchMember } from '../api/api'; // Use fetchMember for child data fetching
import { Container, Grid, TextField, Button, Typography, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

const UserDetails = () => {
  const { childId } = useParams(); // Get the childId from the URL (if any)
  const { user, setUser } = useContext(AuthContext); // Get user context
  const [formData, setFormData] = useState({ ...user });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  useEffect(() => {
    // If we're editing a child member, fetch the child data
    if (childId) {
      const fetchChildDetails = async () => {
        try {
          const childData = await fetchMember(childId); // Fetch the child member's details
          setFormData(childData); // Populate form with child details
        } catch (err) {
          setError('Failed to fetch child details.');
        }
      };
      fetchChildDetails();
    } else {
      // If we're editing the user's own details, populate the form with their details
      setFormData({ ...user });
    }
  }, [childId, user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedData;
      if (childId) {
        // Update child member details
        updatedData = await updateUserDetails(formData, childId); // Pass the childId to update child details
      } else {
        // Update logged-in user details
        updatedData = await updateUserDetails(formData);
        setUser(updatedData); // Update user context
      }
      setSuccess('Details updated successfully.');
      setError(null);
    } catch (err) {
      setError('Failed to update details.');
      setSuccess(null);
    }
  };

  const handleClose = () => {
    setError(null);
    setSuccess(null);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        {childId ? 'Edit Child Details' : 'Edit My Details'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              disabled={!!childId} // Disable email if editing a child since children don't have separate emails
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              fullWidth
              required
              disabled={!!childId} // Disable telephone if editing a child
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              name="address_street"
              value={formData.address_street}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              name="address_city"
              value={formData.address_city}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Postcode"
              name="address_postcode"
              value={formData.address_postcode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country"
              name="address_country"
              value={formData.address_country}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {childId ? 'Update Child Details' : 'Update My Details'}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserDetails;
