import axios from 'axios';

const API_URL = '/api';
// const API_URL = 'http://localhost:8000'
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your actual Google Maps API key

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export const login = async (email, password) => {
  try {
    const response = await api.post('/login', new URLSearchParams({ username: email, password: password }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const registerMember = async (memberData) => {
  try {
    const response = await api.post('/register', memberData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchMembers = async () => {
  try {
    const response = await api.get('/members');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchMember = async (id) => {
  try {
    const response = await api.get(`/members/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const updateMember = async (id, memberData) => {
  try {
    const response = await api.put(`/members/${id}`, memberData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchMatches = async () => {
  try {
    const response = await api.get('/matches');
    const matches = response.data;
    for (let match of matches) {
      const address = await geocodeLatLng(match.lat, match.lng);
      match.location = address;
    }
    return matches;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchMatch = async (id) => {
  try {
    const response = await api.get(`/matches/${id}`);
    const match = response.data;
    const address = await geocodeLatLng(match.lat, match.lng);
    match.location = address;
    return match;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const createMatch = async (matchData) => {
  try {
    const response = await api.post('/matches', matchData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const updateMatch = async (id, matchData) => {
  try {
    const response = await api.put(`/matches/${id}`, matchData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const deleteMatch = async (id) => {
  try {
    const response = await api.delete(`/matches/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await api.get('/members/me');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const updateUserDetails = async (userData) => {
  try {
    const response = await api.put('/members/me', userData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

const geocodeLatLng = async (lat, lng) => {
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`);
    const results = response.data.results;
    if (results.length > 0) {
      return results[0].formatted_address;
    } else {
      return 'Unknown location';
    }
  } catch (error) {
    return 'Unknown location';
  }
};

export const fetchMembersByTeam = async (teamName) => {
  const response = await axios.get(`${API_URL}/members/team/${teamName}`);
  return response.data;
};

export const updateMatchMembers = async (matchId, memberIds) => {
  const response = await axios.put(`${API_URL}/matches/${matchId}/members`, memberIds);
  return response.data;
};

export const getMatchesForMember = async (memberId) => {
  const response = await axios.get(`/members/${memberId}/matches`);
  return response.data;
};

export const getMatchesForTeam = async (teamName) => {
  const response = await axios.get(`/teams/${teamName}/matches`);
  return response.data;
};

export const createChildMember = async (parentId, childData) => {
  try {
    console.log(childData);  // Log childData to verify the fields before sending the request
    const response = await api.post(`/members/${parentId}/children`, childData);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const getChildrenForParent = async (parentId) => {
  try {
    const response = await api.get(`/members/${parentId}/children`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchMemberByEmail = async (email) => {
  try {
    const response = await api.get(`/members/email/${email}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};

export const fetchTeams = async () => {
  try {
    const response = await api.get('/teams/');
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.detail : error.message);
  }
};