import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchMatch, updateMatchMembers } from '../api/api';
import {
  Container,
  Button,
  Typography,
  Grid,
  CircularProgress,
  TextField
} from '@mui/material';
import MemberSelector from '../components/MemberSelector';

const AddMembersToMatch = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [match, setMatch] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getMatch = async () => {
      try {
        const matchData = await fetchMatch(id);
        setMatch(matchData);
        setSelectedMembers(matchData.team_members);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    getMatch();
  }, [id]);

  const handleMembersChange = (selectedMembers) => {
    setSelectedMembers(selectedMembers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateMatchMembers(id, selectedMembers);
      navigate(`/matches/${id}`);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (!match) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Add Members to {match.team} vs {match.opposition}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MemberSelector
              team={match.team}
              selectedMembers={selectedMembers}
              onChange={handleMembersChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddMembersToMatch;
