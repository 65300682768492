import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchMatch, updateMatch, fetchMembersByTeam } from '../api/api';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationSearchInput from '../components/LocationSearchInput';
import MemberSelector from '../components/MemberSelector';

const MatchDetails = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [match, setMatch] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const getMatch = async () => {
      try {
        const matchData = await fetchMatch(id);
        setMatch(matchData);
        setFormData(matchData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    getMatch();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocationSelect = ({ lat, lng, address }) => {
    setFormData((prev) => ({
      ...prev,
      location: `POINT(${lng} ${lat})`,
      address,
      lat,
      lng,
    }));
  };

  const handleMemberSelect = (selectedMembers) => {
    setFormData((prev) => ({
      ...prev,
      team_members: selectedMembers,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedMatch = await updateMatch(id, formData);
      setMatch(updatedMatch);
      setSuccess('Details updated successfully.');
      setError(null);
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
      setSuccess(null);
    }
  };

  if (loading) return <CircularProgress />;
  if (!match) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="md">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Match Details
        </Typography>
        {user && (
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Team"
              name="team"
              value={formData.team}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Opposition"
              name="opposition"
              value={formData.opposition}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Time"
              name="time"
              type="time"
              value={formData.time}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationSearchInput
              onSelect={handleLocationSelect}
              initialLocation={{
                lat: formData.lat,
                lng: formData.lng,
                address: formData.address,
              }}
              disabled={!isEditing}
            />
          </Grid>
          {isEditing && (
            <Grid item xs={12}>
              <MemberSelector
                team={formData.team}
                selectedMembers={formData.team_members || []}
                onChange={handleMemberSelect}
              />
            </Grid>
          )}
          {isEditing && (
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save Changes
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <Button component={Link} to="/matches" variant="outlined" color="primary" style={{ marginTop: '20px' }}>
        Back to Matches
      </Button>
    </Container>
  );
};

export default MatchDetails;
