import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { createMatch } from '../api/api';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress
} from '@mui/material';
import LocationSearchInput from '../components/LocationSearchInput';
import MemberSelector from '../components/MemberSelector'; // Import the MemberSelector component

const MatchForm = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    team: '',
    opposition: '',
    date: '',
    time: '',
    location: '',
    lat: '',
    lng: '',
    address: '',
    team_members: [] // Add team_members to the form data
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLocationSelect = ({ lat, lng, address }) => {
    setFormData((prev) => ({
      ...prev,
      location: `POINT(${lng} ${lat})`,
      lat,
      lng,
      address
    }));
  };

  const handleMembersChange = (selectedMembers) => {
    setFormData((prev) => ({
      ...prev,
      team_members: selectedMembers
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const matchData = {
        ...formData,
      };
      await createMatch(matchData);
      setLoading(false);
      navigate('/matches');
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>Create Match</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Team"
              name="team"
              value={formData.team}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Opposition"
              name="opposition"
              value={formData.opposition}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Time"
              name="time"
              type="time"
              value={formData.time}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationSearchInput onSelect={handleLocationSelect} />
          </Grid>
          <Grid item xs={12}>
            <MemberSelector
              team={formData.team}
              selectedMembers={formData.team_members}
              onChange={handleMembersChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {loading ? <CircularProgress size={24} /> : 'Create Match'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default MatchForm;


