import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchMatches } from '../api/api';
import {
  Container,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress
} from '@mui/material';

const MatchesPage = () => {
  const { user } = useContext(AuthContext);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getMatches = async () => {
      try {
        const matchesData = await fetchMatches();
        setMatches(matchesData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    getMatches();
  }, []);

  return (
    <Container>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Matches
      </Typography>
      {user && user.role === 'admin' && (
        <Button component={Link} to="/matches/new" variant="contained" color="primary" style={{ marginBottom: '20px', float: 'right' }}>
          Add Match
        </Button>
      )}
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team</TableCell>
                <TableCell>Opposition</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches.map((match) => (
                <TableRow key={match.id} component={Link} to={`/matches/${match.id}`} style={{ textDecoration: 'none' }}>
                  <TableCell>{match.team}</TableCell>
                  <TableCell>{match.opposition}</TableCell>
                  <TableCell>{new Date(match.date).toLocaleDateString()}</TableCell>
                  <TableCell>{match.time}</TableCell>
                  <TableCell>{match.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default MatchesPage;
