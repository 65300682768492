import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, fetchCurrentUser } from '../api/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (email, password) => {
    try {
      const userData = await login(email, password);
      localStorage.setItem('token', userData.access_token);  // Store the token
      await fetchUserDetails();  // Fetch and set user details after login
      setError(null);
      navigate('/');
    } catch (error) {
      console.error('Login failed', error);
      setError(error.message);
    }
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');  // Clear the token
    setError(null);
    navigate('/login');
  };

  const fetchUserDetails = async () => {
    try {
      const userData = await fetchCurrentUser();
      setUser(userData);
    } catch (error) {
      console.error('Fetching user details failed', error);
      setError(error.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserDetails();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, error, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
