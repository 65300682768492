import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { fetchMember, updateMember, fetchTeams } from '../api/api';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const MemberDetails = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [member, setMember] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const getMember = async () => {
      try {
        const memberData = await fetchMember(id);
        setMember(memberData);
        setFormData(memberData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    getMember();
  }, [id]);

  useEffect(() => {
    const getTeams = async () => {
      const teamsData = await fetchTeams();
      setTeams(teamsData);
    };

    getTeams();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === 'team_id' ? Number(value) : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedMember = await updateMember(id, formData);
      setMember(updatedMember);
      setSuccess('Details updated successfully.');
      setError(null);
      setIsEditing(false);
    } catch (err) {
      setError(err.message);
      setSuccess(null);
    }
  };

  if (loading) return <CircularProgress />;
  if (!member) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="md">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Member Details
        </Typography>
        {user && (
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              value={formData.dob}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Street Address"
              name="address_street"
              value={formData.address_street}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              name="address_city"
              value={formData.address_city}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Postcode"
              name="address_postcode"
              value={formData.address_postcode}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country"
              name="address_country"
              value={formData.address_country}
              onChange={handleChange}
              fullWidth
              required
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth disabled={!isEditing || user.role !== 'admin'}>
              <InputLabel id="team-label">Team</InputLabel>
              <Select
                labelId="team-label"
                id="team"
                name="team_id"
                value={formData.team_id || ''}
                label="Team"
                onChange={handleChange}
              >
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Subscription Fees Paid"
              name="subscription_fees_paid"
              value={formData.subscription_fees_paid ? 'Yes' : 'No'}
              onChange={handleChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Consent for Photographs"
              name="consent_for_photographs"
              value={formData.consent_for_photographs ? 'Yes' : 'No'}
              onChange={handleChange}
              fullWidth
              disabled={!isEditing}
            />
          </Grid>
          {isEditing && (
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save Changes
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <Button component={Link} to="/members" variant="outlined" color="primary" style={{ marginTop: '20px' }}>
        Back to Members
      </Button>
    </Container>
  );
};

export default MemberDetails;