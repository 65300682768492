import React, { useState, useEffect } from 'react';
import { fetchMembersByTeam } from '../api/api';
import {
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';

const MemberSelector = ({ team, selectedMembers, onChange }) => {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMembers, setFilteredMembers] = useState([]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        const membersData = await fetchMembersByTeam(team);
        setMembers(membersData);
        setFilteredMembers(membersData);
      } catch (err) {
        console.error(err.message);
      }
    };
    if (team) {
      getMembers();
    }
  }, [team]);

  useEffect(() => {
    const results = members.filter((member) =>
      member.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(results);
  }, [searchTerm, members]);

  const handleToggle = (memberId) => () => {
    const currentIndex = selectedMembers.indexOf(memberId);
    const newChecked = [...selectedMembers];

    if (currentIndex === -1) {
      newChecked.push(memberId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };

  return (
    <div>
      <TextField
        label="Search Members"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
      />
      <List>
        {filteredMembers.map((member) => (
          <ListItem key={member.id} button onClick={handleToggle(member.id)}>
            <ListItemText primary={member.name} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={handleToggle(member.id)}
                checked={selectedMembers.indexOf(member.id) !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default MemberSelector;


