import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Loader } from '@googlemaps/js-api-loader';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const LocationSearchInput = ({ onSelect, initialLocation, disabled }) => {
  const inputRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(initialLocation || { lat: 0, lng: 0 });
  const [address, setAddress] = useState(initialLocation?.address || '');

  useEffect(() => {
    console.log('Initial location data:', initialLocation); // Log the initial location data

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      const { maps } = window.google;
      const map = new maps.Map(mapRef.current, {
        center: selectedLocation,
        zoom: 15,
        mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID, // Include your Map ID here
      });

      const autocomplete = new maps.places.Autocomplete(inputRef.current, {
        types: ['geocode'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const address = place.formatted_address;
        setSelectedLocation({ lat, lng });
        setAddress(address);
        onSelect({ lat, lng, address });
      });

      const MarkerConstructor = maps.marker?.AdvancedMarkerElement || maps.Marker;

      markerRef.current = new MarkerConstructor({
        position: selectedLocation,
        map,
        draggable: !disabled,
      });

      markerRef.current.addListener('dragend', (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setSelectedLocation({ lat, lng });
        geocodeLatLng(lat, lng);
      });

      map.addListener('click', (e) => {
        if (!disabled) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
          setSelectedLocation({ lat, lng });
          geocodeLatLng(lat, lng);
        }
      });

      function geocodeLatLng(lat, lng) {
        const geocoder = new maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const formattedAddress = results[0].formatted_address;
              setAddress(formattedAddress);
              onSelect({ lat, lng, address: formattedAddress });
            } else {
              console.error('No results found');
            }
          } else {
            console.error('Geocoder failed due to:', status);
          }
        });
      }

      if (initialLocation) {
        map.setCenter(initialLocation);
        markerRef.current.setPosition(initialLocation);
      }
    }).catch(error => {
      console.error('Error loading Google Maps API:', error);
    });
  }, [initialLocation, onSelect, selectedLocation.lat, selectedLocation.lng, disabled]);

  return (
    <div>
      <TextField
        inputRef={inputRef}
        label="Location"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        fullWidth
        disabled={disabled}
      />
      <div ref={mapRef} style={containerStyle} />
    </div>
  );
};

export default LocationSearchInput;
