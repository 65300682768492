import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { createChildMember, fetchMemberByEmail, fetchTeams } from '../api/api';

const AddChild = () => {
  const [childData, setChildData] = useState({
    name: '',
    dob: '',
    address_street: '',
    address_city: '',
    address_postcode: '',
    address_country: '',
    consent_for_photographs: false,
    team: '',
    accepts_risk: false,
  });
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const parentEmail = location.state?.parentEmail || '';

  useEffect(() => {
    if (!parentEmail) {
      console.error('Parent email is not provided');
      // Optionally redirect or show an error if parentEmail is missing
      navigate('/');
    }

    const getTeams = async () => {
      const teamsData = await fetchTeams();
      setTeams(teamsData);
    };
  
    // Fetch parent data based on parentEmail
    const fetchParentData = async () => {
      if (parentEmail) {
        const parent = await fetchMemberByEmail(parentEmail);
        setChildData((prevData) => ({
          ...prevData,
          email: parent.email,         // Pre-fill with parent's email
          telephone: parent.telephone, // Pre-fill with parent's telephone
        }));
      }
    };
  
    getTeams();
    fetchParentData();
  }, [parentEmail, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setChildData({
      ...childData,
      [name]: type === 'checkbox' ? checked : value,
    });
    setError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!childData.accepts_risk) {
      setError(true);
      return;
    }
    
    try {
      // Fetch parent by email
      const parent = await fetchMemberByEmail(parentEmail);
      
      // Prepare child data to include parent's email and telephone
      const childRequestData = {
        ...childData,
        email: parent.email,               // Use parent's email
        telephone: parent.telephone,       // Use parent's telephone
        first_aid_qualification: false,     // Set to null as you specified
        team_id: teams.find((team) => team.name === childData.team)?.id,  // Set team_id based on team name
      };
      
      // Call the API to create child member
      await createChildMember(parent.id, childRequestData);
      
      // Redirect or navigate to home page after successful creation
      navigate('/');
    } catch (error) {
      console.error(error);
      // Handle error, e.g. display an error message to the user
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Add Child
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Child's Name"
              name="name"
              value={childData.name}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              value={childData.dob}
              onChange={handleChange}
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Street"
              name="address_street"
              value={childData.address_street}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address City"
              name="address_city"
              value={childData.address_city}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Postcode"
              name="address_postcode"
              value={childData.address_postcode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address Country"
              name="address_country"
              value={childData.address_country}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={childData.consent_for_photographs}
                  onChange={handleChange}
                  name="consent_for_photographs"
                />
              }
              label="Consent for Photographs"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
                <InputLabel id="team-label">Team</InputLabel>
                <Select
                labelId="team-label"
                id="team"
                name="team"
                value={childData.team}
                label="Team"
                onChange={handleChange}
                >
                {teams.map((team) => (
                    <MenuItem key={team.id} value={team.name}>
                    {team.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={childData.accepts_risk}
                  onChange={handleChange}
                  name="accepts_risk"
                  required
                />
              }
              label={
                <Typography variant="body2">
                  I accept this policy on behalf of my child: Yate Hockey Club is not liable for any injuries resulting from the use of borrowed equipment, such as facemasks and goalkeeper gear, should it become damaged during use. We strongly encourage all members to purchase their own equipment for safety and reliability. By continuing, you acknowledge and accept this policy. Do you agree?
                </Typography>
              }
            />
            {error && <Typography color="error">This field is required</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Add Child
            </Button>
            <Button onClick={() => navigate('/')} variant="outlined" color="primary" style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddChild;