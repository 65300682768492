import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import '../styles.css';
import { fetchTeams, getChildrenForParent } from '../api/api';

const HomePage = () => {
  const { user, fetchUserDetails } = useContext(AuthContext);
  const [myMatches, setMyMatches] = useState([]);
  const [teamMatches, setTeamMatches] = useState([]);
  const [children, setChildren] = useState([]);
  const [teams, setTeams] = useState([]);
  const navigate = useNavigate();

  // Fetch user details and children when user is available
  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        await fetchUserDetails(); // Fetch user details if not already fetched
      }
    };
    fetchData();
  }, [user, fetchUserDetails]);

  // Fetch children after user is fetched
  useEffect(() => {
    if (user && user.id) {
      fetchChildren(user.id);
    }
  }, [user]);

  // Fetch teams for dropdown or display
  useEffect(() => {
    const getTeams = async () => {
      const teamsData = await fetchTeams();
      setTeams(teamsData);
    };

    getTeams();
  }, []);


  // useEffect(() => {
  //   if (user) {
  //     fetchMyMatches(user.id);
  //     fetchMyTeamMatches(user.team);
  //   }
  // }, [user]);

  const fetchChildren = async (parentId) => {
    try {
      const response = await getChildrenForParent(parentId);
      setChildren(response);
    } catch (error) {
      console.error('Error fetching children:', error);
    }
  };

  const handleAddChild = () => {
    // Redirect to a form page to add a new child and pass in the parent's email address
    if (user) {
      navigate('/add-child', { state: { parentEmail: user.email } });
    }
  };

  const handleChildClick = (childId) => {
    navigate(`/edit-child/${childId}`); // Navigate to the child details page
  };

  // const fetchMyMatches = async (userId) => {
  //   try {
  //     const response = await axios.get(`/members/${userId}/matches`);
  //     setMyMatches(response.data);
  //   } catch (error) {
  //     console.error('Error fetching my matches:', error);
  //   }
  // };

  // const fetchMyTeamMatches = async (teamName) => {
  //   try {
  //     const encodedTeamName = encodeURIComponent(teamName);
  //     const response = await axios.get(`/teams/${encodedTeamName}/matches`);
  //     setTeamMatches(response.data);
  //   } catch (error) {
  //     console.error('Error fetching my team\'s matches:', error);
  //   }
  // };

  return (
    <div className="homepage-container">
      <div className="sidebar">
        <h2>User Details</h2>
        {user ? (
          <>
            <p><strong>Name:</strong> {user.name}</p>
            <p><strong>Team:</strong> {teams.find((team) => team.id === user.team_id)?.name || ''}</p>
            <p>
              <strong>Subscription Fees Paid:</strong> 
              {user.subscription_fees_paid ? (
                <span style={{ color: 'green' }}>✔️</span>
              ) : (
                <span style={{ color: 'red' }}>❌</span>
              )}
            </p>
            <Link to="/my-details" className="link-button mb-1">Edit My Details</Link>
            <button onClick={handleAddChild} className="link-button">Add Child</button>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <div className="main-content">
        <h1>Welcome, {user ? user.name : 'User'}</h1>
        <p>Here you can find your membership details and more.</p>
        <div>
          {/* <h2>My Matches</h2>
          {myMatches.length > 0 ? (
            <ul>
              {myMatches.map((match) => (
                <li key={match.id}>{match.date} - {match.team} vs {match.opposition}</li>
              ))}
            </ul>
          ) : (
            <p>No matches found.</p>
          )}
        </div>
        <div>
          <h2>My Team's Matches</h2>
          {teamMatches.length > 0 ? (
            <ul>
              {teamMatches.map((match) => (
                <li key={match.id}>{match.date} - {match.team} vs {match.opposition}</li>
              ))}
            </ul>
          ) : (
            <p>No matches found.</p>
          )} */}
          <h2>My Children</h2>
          {children.length > 0 ? (
            <ul>
              {children.map((child) => (
                <li key={child.id} onClick={() => handleChildClick(child.id)} style={{ cursor: 'pointer' }}>
                  {child.name} - {new Date(child.dob).toLocaleDateString()}  {/* Format DOB */}
                </li>
              ))}
            </ul>
          ) : (
            <p>No children found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
