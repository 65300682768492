import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
  Box,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB } from 'date-fns/locale';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
}));

const RegistrationForm = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    dob: null,
    telephone: '',
    addressStreet: '',
    addressCity: '',
    addressPostcode: '',
    addressCountry: 'United Kingdom',
    consentForPhotographs: false,
    firstAidQualification: false,
    acceptsRisk: false,
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    setError(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formData.acceptsRisk) {
      setError(true);
      return;
    }
    const userData = {
      user: {
        email: formData.email,
        password: formData.password,
        name: formData.name,
        dob: isMobile ? format(formData.dob, 'yyyy-MM-dd') : format(formData.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        telephone: formData.telephone,
        address_street: formData.addressStreet,
        address_city: formData.addressCity,
        address_postcode: formData.addressPostcode,
        address_country: formData.addressCountry,
        consent_for_photographs: formData.consentForPhotographs,
        first_aid_qualification: formData.firstAidQualification,
        accepts_risk: formData.acceptsRisk,
      },
      g_recaptcha_response: captchaToken,
    };
    fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        } else {
          throw new Error('Registration failed');
        }
      })
      .then((data) => {
        setFormData({
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          dob: null,
          telephone: '',
          addressStreet: '',
          addressCity: '',
          addressPostcode: '',
          addressCountry: 'United Kingdom',
          consentForPhotographs: false,
          firstAidQualification: false,
          acceptsRisk: false,
        });
        setShowThankYouMessage(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error.message);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ padding: theme.spacing(4), backgroundColor: '#f5f5f5', borderRadius: theme.spacing(1), boxShadow: theme.shadows[5] }}>
      {showThankYouMessage ? (
        <Typography variant="h4" align="center">
          Thank you for your registration!
        </Typography>
      ) : (
        <>
            {errorMessage && (
            <Typography variant="body1" color="error" align="center" gutterBottom>
              {errorMessage}
            </Typography>
          )}
          <Box textAlign="center">
            <Typography variant="h4" gutterBottom>
              Member Registration
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              {isMobile ? (
                <DatePicker
                  label="Date of Birth"
                  value={formData.dob}
                  onChange={handleDateChange}
                  inputFormat="dd/MM/yyyy"
                  fullWidth
                  required
                  margin="normal"
                  className={classes.datePicker}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <TextField
                  label="Date of Birth"
                  name="dob"
                  type="datetime-local"
                  value={formData.dob ? format(formData.dob, "yyyy-MM-dd'T'HH:mm") : ''}
                  onChange={(event) => handleDateChange(new Date(event.target.value))}
                  required
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </LocalizationProvider>
            <TextField
              label="Telephone"
              name="telephone"
              value={formData.telephone}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
                    <TextField
              label="Street Address"
              name="addressStreet"
              value={formData.addressStreet}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="City"
              name="addressCity"
              value={formData.addressCity}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Postcode"
              name="addressPostcode"
              value={formData.addressPostcode}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Country"
              name="addressCountry"
              value={formData.addressCountry}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.consentForPhotographs}
                  onChange={handleInputChange}
                  name="consentForPhotographs"
                />
              }
              label="Consent for Photographs"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.firstAidQualification}
                  onChange={handleInputChange}
                  name="firstAidQualification"
                />
              }
              label="First Aid Qualification"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.acceptsRisk}
                  onChange={handleInputChange}
                  name="acceptsRisk"
                  required
                />
              }
              label={
                <Typography variant="body2">
                  Yate Hockey Club is not liable for any injuries resulting from the use of borrowed equipment, such as facemasks and goalkeeper gear, should it become damaged during use. We strongly encourage all members to purchase their own equipment for safety and reliability. By continuing, you acknowledge and accept this policy. Do you agree?
                </Typography>
              }
            />
            {error && <Typography color="error">This field is required</Typography>}
            <ReCAPTCHA
              sitekey="6LdnJzsqAAAAABaL27x8d8orxPp5xBIdL1oHlwXw"
              onChange={handleCaptchaChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: theme.spacing(2) }}
            >
              Register
            </Button>
          </form>
        </>
      )}
    </Container>
  );
};

export default RegistrationForm;